class ConvertDate {
  date: string;

  constructor (dateString: string) {
    this.date = dateString
  }

  convertMe() {
    const date = new Date(this.date)
    const day = ('0' + (date.getDate())).slice(-2)
    const month =  ('0' + (date.getMonth() + 1)).slice(-2)
    const year = date.getFullYear()


    const convertedDate = `${year}-${month}-${day}`
    const convertedTime = date.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1")
    const fullDate = `${convertedDate} ${convertedTime}`

    return fullDate
  }

  convertToYyyyMmDd() {
    console.log('> this.date', this.date)
    console.log('> new Date', new Date(this.date))
    const date = new Date(this.date)
    const day = ('0' + (date.getDate())).slice(-2)
    const month =  ('0' + (date.getMonth() + 1)).slice(-2)
    const year = date.getFullYear()


    const convertedDate = `${year}-${month}-${day}`
    const fullDate = convertedDate

    return fullDate
  }
}

export default ConvertDate
